import * as yup from 'yup'
import i18n from '@/localization/i18n'

const t = i18n.global.t

// const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]*$/
const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[^\s]*$/


const schema = yup.object().shape({
  password: yup.string().required(t('w_msg_frmVal_requiredCurrentPassword')),
  new_password: yup
    .string()
    .matches(passwordRegex, t('w_msg_frmVal_passwordFormat'))
    .min(8, t('w_msg_frmVal_minCharacters', { num: 8 }))
    .required(t('w_msg_frmVal_requiredNewPassword')),
  confirm_password: yup
    .string()
    .required(t("w_msg_frmVal_requiredConfirmPassword"))
    // @ts-ignore
    .oneOf([yup.ref('new_password'), null], t('w_msg_frmVal_passwordsDifferent'))
})

export default schema