import { defineStore } from "pinia";

/**
 * This store is used to keep any global ui states
 */
const useGlobalStore = defineStore('global', {
    state: () => ({
        openProfileDropDown: false, // Marked for deletion
        launchTour: false, // Triggers tour when set to true
        showCookieBanner: false
    }),
    getters: {
        getOpenProfileDropdown: (state) => state.openProfileDropDown,
        getLaunchTour: (state) => state.launchTour,
        getShowCookieBanner: (state) => state.showCookieBanner
    },
    actions: {
        setOpenProfileDropdown(value: any) {
            this.openProfileDropDown = value
        },
        setLaunchTour(value: any) {
            this.launchTour = value
        },
        setShowCookieBanner(value: boolean) {
            this.showCookieBanner = value
        }
    }
})

export default useGlobalStore