import { IUser } from "@/models/interfaces/interfaces"

/**
 * @fileoverview
 * This file uses interface merging to avoid redefining fields in the extended interface
 * To learn more about interface merging go to:
 * {@link https://www.typescriptlang.org/docs/handbook/declaration-merging.html}
 */

/**
 * Represents a user (connected user)
 * @class
 */
export interface User extends IUser { }
export class User {
    /**
     * Creates a user
     * @constructor
     * @param {IUser} userData 
     * @example
     * const user = new User({ id: 1, token:... } : IUser)
     */
    constructor(userData: IUser) {
        Object.assign(this, userData)
    }
}