import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { Ref, reactive, ref, watch } from "vue";
import emailSchema from '@/validation/emailChangeSchema'
import newPasswordSchema from '@/validation/newPasswordSchema'
import { useAlertsStore, useFormAlertsStore } from '@/stores/alerts';
import { changePassword, verifyCode, verifyContact } from "@/services";
import { EmailVerifiedStatus } from "@/utils/enums";
import i18n from '@/localization/i18n'

const t = i18n.global.t

export const useAuthInfoFormStore = defineStore('auth-info', () => {
    const userStore = useUserStore()
    const loginAlerts = useFormAlertsStore()
    const alerts = useAlertsStore()

    const password = ref('')
    const savePasswordLoading = ref(false)
    const isVerifyingEmail = ref(false)
    const hasChanges = ref(false)

    const email_verification_code_sent = ref(false)
    const email_otp_input_value: Ref<any> = ref(null)
    const sendOtpLoading = ref(false)

    const isClient = typeof window !== 'undefined'

    const emailVerificationSuccess = ref(false)

    const { user } = storeToRefs(userStore)

    const emailFormData = reactive({
        email: ''
    })

    const passwordFormData = reactive({
        password: '',
        new_password: '',
        confirm_password: ''
    })

    const emailFormErrors = reactive({
        email: ''
    })

    const passwordFormErrors = reactive({
        password: '',
        new_password: '',
        confirm_password: ''
    })

    const openBeforeSaveModal = () => {
        let triggerButton = document.getElementById('beforeSaveButton')
        triggerButton?.click()
    }

    const openChangeEmailModal = () => {
        const modalButton: any = document.querySelector('#newEmailModalButton')
        modalButton?.click()
    }

    watch(emailFormData, () => {
        if (emailFormData.email) {
            hasChanges.value = true
        }
    })

    watch(passwordFormData, () => {
        if (passwordFormData.password || passwordFormData.new_password || passwordFormData.confirm_password) {
            hasChanges.value = true
        }
    })

    const submitNewEmail = async () => {
        // Check if user changed email
        // when they're not allowed to make updates
        if (!user.value.updatable && (emailFormData.email !== user.value.email)) {
            openBeforeSaveModal()
        } else {

            try {
                await emailSchema.validate(emailFormData, { abortEarly: false })

                //in this case (send_otp=false) the passeword should be optional for the check
                let payload = {
                    "client_id": user.value.party_id,
                    "contact": emailFormData.email,
                    "send_otp": false
                }
                isVerifyingEmail.value = true
                try {
                    const response = await verifyContact(payload)
                    isVerifyingEmail.value = false

                    emailFormErrors.email = ""
                    openChangeEmailModal()

                } catch (error: any) {
                    isVerifyingEmail.value = false
                    // let statusCode = error.response.status
                    emailFormErrors.email = t("w_msg_emailInvalid");
                }
                //openChangeEmailModal()
            } catch (error: any) {
                error.inner.forEach((e: any) => {
                    // @ts-ignore
                    emailFormErrors[e.path] = e.message;
                });
            }
        }
    }

    function validatePassword() {
        passwordFormErrors.password = '',
            passwordFormErrors.new_password = '',
            passwordFormErrors.confirm_password = ''
        newPasswordSchema.validate(passwordFormData, { abortEarly: false })
            .then(() => {
            })
            .catch((error: any) => {
                error.inner.forEach((e: any) => {
                    // @ts-ignore
                    passwordFormErrors[e.path] = e.message;
                });
            })
    }

    const resetEmail = () => {
        emailFormData.email = ''
    }

    const resetPassword = () => {
        passwordFormData.new_password = ''
        passwordFormData.password = ''
        passwordFormData.confirm_password = ''
    }

    const onChangePassword = async () => {
        loginAlerts.clearAlerts()

        try {
            await newPasswordSchema.validate(passwordFormData, { abortEarly: false })
            savePasswordLoading.value = true
            const payload = {
                "account_id": user.value.account_id,
                "old_password": passwordFormData.password,
                "new_password": passwordFormData.new_password
            }

            try {
                const response = await changePassword(payload)
                savePasswordLoading.value = false

                resetPassword()

                hasChanges.value = false
                alerts.putAlert({ 'code': 'passwordSaveSucces', 'scope': 'general' })
            } catch (error: any) {
                if (error.response.data.code === 'Err-09') {
                    savePasswordLoading.value = false
                    loginAlerts.putAlert({ 'code': 'wrongOldPassword', 'scope': 'changePassword' })
                } else {
                    savePasswordLoading.value = false
                }
            }

        } catch (error: any) {
            error.inner.forEach((e: any) => {
                // @ts-ignore
                passwordFormErrors[e.path] = e.message;
            });
        }
    }

    const verifyEmail = async (resend_otp = false) => {
        const alerts = useAlertsStore()
        const loginAlerts = useFormAlertsStore()

        loginAlerts.clearAlerts()
        email_otp_input_value.value = null
        sendOtpLoading.value = true

        let payload = {
            "client_id": user.value.party_id,
            "contact": emailFormData.email,
            "password": password.value,
            "send_otp": true
        }

        try {
            const response = await verifyContact(payload)
            email_verification_code_sent.value = true
            if (isClient) {
                localStorage.setItem('email_otp_token', response.data.otp_token)
            }
            if (resend_otp) {
                alerts.putAlert({ 'code': 'resendEmailCodeSuccess', 'scope': 'verifyEmailModal' })
            }
            sendOtpLoading.value = false
        } catch (error: any) {
            if (error.response.data.code === "Err-20") {
                loginAlerts.putAlert({ 'code': 'wrongPasswordEmailVerification', 'scope': 'verifyEmailModal' })
            }
            sendOtpLoading.value = false
        }
    }

    const submitEmailOTP = async () => {
        const loginAlerts = useFormAlertsStore()
        let code_token = isClient ? localStorage.getItem("email_otp_token") : null
        loginAlerts.clearAlerts()

        sendOtpLoading.value = true
        const payload = {
            "client_id": user.value.party_id,
            "contact": emailFormData.email,
            "otp": email_otp_input_value.value,
            "otp_token": code_token,
        }

        try {
            const response = await verifyCode(payload)
            user.value.email_verified_status = EmailVerifiedStatus.g_lst_account_email_status_verified
            sendOtpLoading.value = false
            emailVerificationSuccess.value = true
            hasChanges.value = false

            user.value.email = emailFormData.email
            resetEmail()
        } catch (error: any) {
            if (error.response.data.code === 'Err-16') {
                loginAlerts.putAlert({ 'code': 'emailVerificationCodeWrong', 'scope': 'verifyEmailModal' })
            }
            sendOtpLoading.value = false
        }
    }

    function onModalHidden() {
        clearData()
    }

    const clearData = () => {
        email_verification_code_sent.value = false
        email_otp_input_value.value = ''
        password.value = ''
        resetEmail()
        resetPassword()
        loginAlerts.clearAlerts()

        hasChanges.value = false
    }

    return {
        password,
        savePasswordLoading,
        hasChanges,
        email_verification_code_sent,
        email_otp_input_value,
        sendOtpLoading,
        emailVerificationSuccess,
        emailFormData,
        passwordFormData,
        emailFormErrors,
        passwordFormErrors,
        isVerifyingEmail,

        submitEmailOTP,
        verifyEmail,
        onChangePassword,
        resetPassword,
        resetEmail,
        validatePassword,
        submitNewEmail,
        onModalHidden,
        clearData
    }
})