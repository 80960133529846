export { onPageTransitionEnd }

import type { OnPageTransitionEndAsync } from 'vike/types'

import { getAppInstance } from './appInstance'

const onPageTransitionEnd: OnPageTransitionEndAsync = async (
    pageContext
): ReturnType<OnPageTransitionEndAsync> => {
    function scrollToTop() {
        document.documentElement.scrollTop = 0; // For most browsers
        document.body.scrollTop = 0; // For older browsers
    }

    scrollToTop()

    const app = getAppInstance()
    app.config.globalProperties.$Progress.finish()

    const gtag = app.config.globalProperties.$gtag;
    console.log("gtag", gtag)
    if (gtag) {
        gtag.pageview({
            page_path: pageContext.urlPathname,
            page_title: document.title,
        });
    } else {
        console.warn('Google Analytics plugin not found.');
    }
}