<script setup lang="ts">
import { useAlertsStore } from '@/stores/alerts.ts';
import CookieConsentBanner from '@/views/components/CookieConsentBanner.vue';
import {defineAsyncComponent, watch} from 'vue';
// import { useRouter } from 'vue-router';
import { onMounted, onBeforeMount } from 'vue';
import { useUserStore } from '@/stores/user.ts';
import { getUserFromLocalStorage, isAuth, logout } from '@/utils/functions.ts';
import { getProfileInfo } from '@/services';
import axios from 'axios';
import { ErrorResponse } from "@/utils/enums.ts"
// @ts-ignore
import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';

import locales from '@/localization/locales/locales.json'
import { IUser } from '../models/interfaces/interfaces.ts';
import useAuthStore from '../stores/auth.ts';
import { usePageContext } from "vike-vue/usePageContext";

const pageContext = usePageContext()

// Set the default time zone to 'Europe/Paris' for the whole project
moment.tz.setDefault('Europe/Paris');

const VITE_ASSET_URL = import.meta.env.VITE_ASSET_URL

if (VITE_ASSET_URL.includes(".dev.")) {
  console.log('BASE_URL', import.meta.env.VITE_BACKEND_BASE_URL)
  console.log('MODE', import.meta.env.MODE)
  console.log('PROD', import.meta.env.PROD)
  console.log('DEV', import.meta.env.DEV)
  console.log('ASSET URL',VITE_ASSET_URL)
  console.log('PROJECT VERSION', import.meta.env.VITE_PROJECT_VERSION)


  console.log('\n\n')
}


const NotificationToast = defineAsyncComponent(() => import('@/views/components/NotificationToast.vue'));
const alerts = useAlertsStore();
// const router = useRouter()
// const internalInstance = getCurrentInstance()
const useI18nInstance = useI18n();
const auth = useAuthStore()

const config = {
  headers: {
    'Cache-Control': 'no-store',
  },
}

onBeforeMount(() => {
  axios.get(VITE_ASSET_URL + 'assets/locales/locales.json', config)
    .then((response) => {
      useI18nInstance.setLocaleMessage('fr', response.data)
    })
    .catch((error) => {
      useI18nInstance.setLocaleMessage('fr', locales.fr)
      console.log('Error fetching locale.json from minio:', error);
      console.log('Falling back to local locales.json...')
      
    })
})


onMounted(() => {
  // router.beforeEach(() => {
  //   internalInstance?.appContext.config.globalProperties.$Progress.start()
  // });
  //
  // router.afterEach(() => {
  //   internalInstance?.appContext.config.globalProperties.$Progress.finish();
  // });
 
  if (isAuth()) {
    const user = getUserFromLocalStorage()
    let userStore = useUserStore()

    if (user === null) {
      getProfileInfo()
        .then((response) => {
          let user_info:IUser = response.data

          sessionStorage.setItem('user', JSON.stringify(user_info))
          userStore.addUser(user_info)
          auth.setIsLoggedIn(true)
        })
        .catch((err: any) => {
          if (err.response?.data.code == ErrorResponse.USER_NOT_FOUND) {
            logout()
          }
        })
    } else {
      userStore.addUser(user)
      auth.setIsLoggedIn(true)
    }
  }
})

</script>

<template>
  <template v-if="alerts.getSize > 0">
    <NotificationToast />
  </template>
  <slot />
  <vue-progress-bar></vue-progress-bar>
  <CookieConsentBanner></CookieConsentBanner>
</template>

<style scoped>
</style>
