import {getAppInstance} from "@/pages/appInstance.ts";

export { onHydrationEnd }

import type { OnHydrationEndAsync } from 'vike/types'

const onHydrationEnd: OnHydrationEndAsync = async (
    pageContext
): ReturnType<OnHydrationEndAsync> => {
    // Add the google analytics' logic here as well
    // in order to record the first loaded page as a page visit as well

    const app = getAppInstance()

    const gtag = app.config.globalProperties.$gtag;
    if (gtag) {
        gtag.pageview({
            page_path: pageContext.urlPathname,
            page_title: document.title,
        });
    } else {
        console.warn('Google Analytics plugin not found.');
    }
}