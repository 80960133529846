import { IAddress } from "./interfaces/interfaces"

/**
 * @fileoverview
 * This file uses interface merging to avoid redefining fields in the extended interface
 * To learn more about interface merging go to:
 * {@link https://www.typescriptlang.org/docs/handbook/declaration-merging.html}
 */

/**
 * Represents an address
 * @class
 */
export interface Address extends IAddress {}
export class Address {
    /**
     * Creates an address
     * @constructor
     * @param {IAddress} addressData 
     * @example
     * const address = new Address({ id: 1, town: 'paris'.. } : IAddress)
     */
    constructor (addressData : IAddress) {
        Object.assign(this, addressData)
    }

    public get is_empty(): boolean {
        return Object.values(this).every((value) => {
            return value === null || value === undefined || value === ''
        })
    }
}