import { defineStore, storeToRefs } from 'pinia'
import { useUserStore } from "@/stores/user";
import { Ref, computed, ref, watch } from 'vue';
import { modifyProfileInfo, verifyCode, verifyContact } from '@/services';
import { useAlertsStore, useFormAlertsStore } from '@/stores/alerts'
import { EmailVerifiedStatus } from '@/utils/enums';
import constants from '@/utils/constants'


export const useUserFavoriteContactStore = defineStore('user-favorite-contact', () => {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const saveLoading = ref(false)
    const sendOtpLoading = ref(false)
    const hasChanges = ref(false)
    const favContactSwitchTarget = ref('')
    const currentPhoneNumber = ref(user.value.phone)
    const password = ref('')

    const phone_verification_code_sent = ref(false)
    const email_verification_code_sent = ref(false)
    const phone_otp_input_value: Ref<any> = ref(null)
    const email_otp_input_value: Ref<any> = ref(null)
    const phoneVerificationSuccess = ref(false)
    const emailVerificationSuccess = ref(false)

    const isClient = typeof window !== 'undefined'

    const get_favorite_contact = computed(() => {
        if (user.value.notif_email === true) {
            return 'notif_email'
        } if (user.value.notif_phone === true) {
            return 'notif_phone'
        } else {
            return 'notif_email'
        }
    })

    // To be used as api payload
    const favorite_contact_data = computed(() => {
        if (favorite_contact.value === 'notif_phone') {
            return {
                notif_email: false,
                notif_phone: true
            }
        } else if (favorite_contact.value === 'notif_email') {
            return {
                notif_email: true,
                notif_phone: false
            }
        } else {
            return {
                notif_email: true,
                notif_phone: false
            }
        }
    })

    const isNewPhoneNumberAdded = computed(() => {
        return currentPhoneNumber.value !== null && currentPhoneNumber.value !== undefined && currentPhoneNumber.value !== user.value.phone
    })

    const favorite_contact = ref(get_favorite_contact.value)

    // Set favrite_contact string value when user object changes
    watch(user, () => {
        // after fetching new user data
        // check if current favorite contact is phone and if a new number has been added
        // in order to prompt the user to verify their phone
        if (isNewPhoneNumberAdded.value && favorite_contact.value === 'notif_phone') {
            const changePhoneInfoButton = document.getElementById("changePhoneInfoButton")
            changePhoneInfoButton?.click()
        }

        favorite_contact.value = get_favorite_contact.value
    })

    watch(favorite_contact, (newVal) => {
        if (newVal === 'notif_email') {
            onEmailSelected()
        } else if (newVal === 'notif_phone') {
            onPhoneSelected()
        }
    })

    const onEmailSelected = () => {
        if (user.value.email_verified_status === 'g_lst_account_email_status_verified') {
            // if email is already verified, just update the hasChanges flag
            hasChanges.value = true
        } else {
            // if email is not verified, reset the radio buttons so the user isn't able to select this option
            favorite_contact.value = get_favorite_contact.value

            // save the desired contact target, in order to automatically select it after verification
            favContactSwitchTarget.value = 'notif_email'

            triggerEmailVerificationModal()
        }
    }

    const onPhoneSelected = () => {
        if (user.value.phone_verified === true) {
            // if phone is already verified, just update the hasChanges flag
            hasChanges.value = true
        } else {
            // if phone is not verified, reset the radio buttons so the user isn't able to select this option
            favorite_contact.value = get_favorite_contact.value

            // save the desired contact target, in order to automatically select it after verification
            favContactSwitchTarget.value = 'notif_phone'

            triggerPhoneVerificationModal()
        }
    }

    const triggerPhoneVerificationModal = () => {
        const verify_phone_modal_button = document.getElementById('verify_phone_modal_button')
        verify_phone_modal_button?.click()
    }

    const triggerEmailVerificationModal = () => {
        const verify_email_modal_button = document.getElementById('verify_email_modal_button')
        verify_email_modal_button?.click()
    }

    const saveChanges = async () => {
        saveLoading.value = true

        let payload = {
            ...favorite_contact_data.value,
        }

        try {
            await modifyProfileInfo(payload)

            saveLoading.value = false
            hasChanges.value = false

            const alerts = useAlertsStore()
            alerts.putAlert({ 'code': 'savaFavoriteContactSuccess', 'scope': 'general' })
        } catch (error: any) {
            saveLoading.value = false
            const alerts = useAlertsStore()

            if (error.response?.status && error.response.status === constants.INVALID_PHONE_ERROR_CODE) {
                alerts.putAlert({ 'code': 'invalidPhoneNumber', 'scope': 'general' })

                return
            }

            alerts.putAlert({ 'code': 'savaFavoriteContactFail', 'scope': 'general' })
        }
    }

    const resetFavariteContact = () => {
        favorite_contact.value = get_favorite_contact.value
    }

    const verifyPhone = async (resend_otp = false) => {
        let payload = {
            "client_id": user.value.party_id,
            "contact": user.value.phone,
        }

        const alerts = useAlertsStore()

        const loginAlerts = useFormAlertsStore()
        loginAlerts.clearAlerts()
        phone_otp_input_value.value = null
        sendOtpLoading.value = true

        try {
            const response = await verifyContact(payload)
            phone_verification_code_sent.value = true
            sendOtpLoading.value = false

            if (isClient) {
                localStorage.setItem('phone_otp_token', response.data.otp_token)
            }
            if (resend_otp) {
                alerts.putAlert({ 'code': 'resendPhoneCodeSuccess', 'scope': 'verifyMobileModal' })
            }
        } catch (error) {
            sendOtpLoading.value = false
        }
    }

    const verifyEmail = async (resend_otp = false) => {

        const alerts = useAlertsStore()

        const loginAlerts = useFormAlertsStore()

        loginAlerts.clearAlerts()
        email_otp_input_value.value = null
        sendOtpLoading.value = true

        let payload = {
            "client_id": user.value.party_id,
            "contact": user.value.email,
            "password": password.value
        }

        try {
            const response = await verifyContact(payload)
            email_verification_code_sent.value = true
            if (isClient) {
                localStorage.setItem('email_otp_token', response.data.otp_token)
            }
            if (resend_otp) {
                alerts.putAlert({ 'code': 'resendEmailCodeSuccess', 'scope': 'verifyEmailModal' })
            }
            sendOtpLoading.value = false
        } catch (error: any) {
            if (error.response.data.code === "Err-20") {
                loginAlerts.putAlert({ 'code': 'wrongPasswordEmailVerification', 'scope': 'verifyEmailModal' })
            }
            sendOtpLoading.value = false
        }
    }

    const submitPhoneOTP = async () => {
        const loginAlerts = useFormAlertsStore()
        let code_token = isClient ? localStorage.getItem("phone_otp_token") : null

        loginAlerts.clearAlerts()

        let payload = {
            "client_id": user.value.party_id,
            "contact": user.value.phone,
            "otp": phone_otp_input_value.value,
            "otp_token": code_token,
        }

        sendOtpLoading.value = true

        try {
            const response = await verifyCode(payload)
            user.value.phone_verified = true

            sendOtpLoading.value = false
            phoneVerificationSuccess.value = true

            if (favContactSwitchTarget.value === 'notif_phone') {
                favorite_contact.value = 'notif_phone'
                favContactSwitchTarget.value = ''

                // save changes immediately after verifying contact
                // in the case where the user was attempting to switch prefered contact to phone
                await saveChanges()
            }

        } catch (error: any) {
            if (error.response.data.code === 'Err-16') {
                loginAlerts.putAlert({ 'code': 'emailVerificationCodeWrong', 'scope': 'verifyMobileModal' })
            }
            sendOtpLoading.value = false
        }
    }

    const submitEmailOTP = async () => {
        const loginAlerts = useFormAlertsStore()
        let code_token = isClient ? localStorage.getItem("email_otp_token") : null
        loginAlerts.clearAlerts()

        sendOtpLoading.value = true


        const payload = {
            "client_id": user.value.party_id,
            "contact": user.value.email,
            "otp": email_otp_input_value.value,
            "otp_token": code_token,
        }

        try {
            const response = await verifyCode(payload)
            user.value.email_verified_status = EmailVerifiedStatus.g_lst_account_email_status_verified
            sendOtpLoading.value = false
            emailVerificationSuccess.value = true

            if (favContactSwitchTarget.value === 'notif_email') {
                favorite_contact.value = 'notif_email'
                favContactSwitchTarget.value = ''

                // save changes immediately after verifying contact
                // in the case where the user was attempting to switch prefered contact to email
                await saveChanges()
            }
        } catch (error: any) {
            if (error.response.data.code === 'Err-16') {
                loginAlerts.putAlert({ 'code': 'emailVerificationCodeWrong', 'scope': 'verifyEmailModal' })
            }
            sendOtpLoading.value = false
        }
    }

    const clearData = () => {
        phone_verification_code_sent.value = false
        email_verification_code_sent.value = false
        email_otp_input_value.value = ''
        phone_otp_input_value.value = ''
        password.value = ''
        const loginAlerts = useFormAlertsStore()
        loginAlerts.clearAlerts()

        hasChanges.value = false
    }

    function onModalHidden() {
        clearData()
    }

    return {
        favorite_contact,
        saveLoading,
        sendOtpLoading,
        password,
        phone_verification_code_sent,
        email_verification_code_sent,
        phone_otp_input_value,
        email_otp_input_value,
        phoneVerificationSuccess,
        emailVerificationSuccess,
        hasChanges,

        onModalHidden,
        triggerPhoneVerificationModal,
        saveChanges,
        resetFavariteContact,
        verifyPhone,
        verifyEmail,
        submitPhoneOTP,
        submitEmailOTP,
        clearData
    }
})