import * as yup from 'yup'
import i18n from '@/localization/i18n'

const t = i18n.global.t

const schema = yup.object().shape({
    company_name: yup
        .string()
        .max(150, t('w_msg_frmVal_maxCharacters', { num: 150 }))
        .required(t('w_msg_frmVal_required')),
    company_reference: yup
        .string()
        .required(t('w_msg_frmVal_required'))
})

export default schema