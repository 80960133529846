import * as yup from 'yup'
import i18n from '@/localization/i18n'

const t = i18n.global.t

const schema = yup.object().shape({
  email: yup.string().email(t('w_msg_frmVal_emailFormat'))
                    .required(t('w_msg_frmVal_required'))
})

export default schema