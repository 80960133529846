import { defineStore } from "pinia";

/**
 * Represents the state management for alerts to be 
 * displayed as Toasts
 */
export const useAlertsStore = defineStore('alerts', {
    state: () => ({
        alerts: [] as any[] // list of alert messages
    }),
    getters: {
        getAlerts: (state) => state.alerts,
        getSize: (state) => state.alerts.length
    },
    actions: {
        putAlert(alert: any) {
            // this.clearAlerts()
            this.alerts.push(alert)
        },
        clearAlerts() {
            this.alerts = []
        },
        removeAlert(alert: any) {
            this.alerts = this.alerts.filter(e => e !== alert)
        }
    }
})


/**
 * Represents the state managment for alerts to be displayed
 * as error messages responses on forms
 */
export const useFormAlertsStore = defineStore('formAlerts', {
    state: () => ({
        alerts: [] as any[]
    }),
    getters: {
        getAlerts: (state) => state.alerts,
        getSize: (state) => state.alerts.length
    },
    actions: {
        putAlert(alert: any) {
            this.clearAlerts()
            this.alerts.push(alert)
        },
        clearAlerts() {
            this.alerts = []
        },
        removeAlert(alert: any) {
            this.alerts = this.alerts.filter(e => e !== alert)
        }
    }
})

