import 'animate.css';
import { onUnmounted } from 'vue';

export default {
  mounted(el: any, binding: any) {
    const animateClasses = binding.value || 'animate__animated animate__fadeIn'; // Default animation classes if not provided
    const offset = 100; // Adjust the offset value to trigger the animation before or after the element comes into view

    const handleIntersection = (entries: any, observer: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                el.classList.add(...animateClasses.split(' '));
                observer.unobserve(entry.target);
            }
        });
    };
  
    const options = {
        root: null, // Use the viewport as the root element
        rootMargin: `-${offset}px`, // Apply the offset to trigger the animation before the element is fully in view
    };
  
    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(el);
  
    // Clean up the observer when the component is unmounted
    onUnmounted(() => {
        observer.disconnect();
    });
  },
};
