import { defineStore } from "pinia";
import { isAuth } from "@/utils/functions";

/**
 * Represents the state managment to track the user 
 * authentication state
 */
const useAuthStore = defineStore('auth', {
    state: () => ({
        isLoggedIn: isAuth() // is true if user is logged in
    }),
    getters: {
        getIsLoggedIn: (state) => state.isLoggedIn
    },
    actions: {
        setIsLoggedIn(isLoggedInState: boolean) {
            this.isLoggedIn = isLoggedInState
        }
    }
})

export default useAuthStore