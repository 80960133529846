// import router from '@/router/router';
import { navigate } from 'vike/client/router'
// @ts-ignore
import VueJwtDecode from 'vue-jwt-decode'
import moment from 'moment-timezone'
import 'moment/locale/fr'
// @ts-ignore
import pica from 'pica';
import { AuthTokenData, IProposal, IUser, IWork } from '@/models/interfaces/interfaces';
import { User } from '@/models/User';
import { Proposal } from '@/models/Proposal';
// import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import useAuthStore from '@/stores/auth';
import { ErrorResponse, GeneralStatus } from './enums';
import { getProfileInfo } from '@/services';

//setting the default timezone to be paris
moment.tz.setDefault('Europe/Paris');

moment.locale('fr', {
    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact: true,
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm'
    },
    calendar: {
        sameDay: '[Aujourd’hui à] LT',
        nextDay: '[Demain à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[Hier à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: 'quelques secondes',
        m: 'une minute',
        mm: '%d minutes',
        h: 'une heure',
        hh: '%d heures',
        d: 'un jour',
        dd: '%d jours',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
    ordinal: function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse: /PD|MD/,
    isPM: function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem: function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // Used to determine first week of the year.
    }
});

export function formatPhoneNumber(num: string | null | undefined) {
    if (num === null || num == undefined) {
        return ''
    }
    // remove all non-digits characters
    num = num.replace(/\D/g, "");

    // add spaces after every two digits
    num = num.replace(/(\d{2})(?=\d)/g, "$1 ");

    return num;
}

export const resizeImage = async (file: any) => {
    const canvas = document.createElement('canvas');

    // set size to 100x100
    canvas.width = 100;
    canvas.height = 100;

    const dataURL = canvas.toDataURL(file.type);
    const blob = await fetch(dataURL).then((res) => res.blob());

    const resizedFile = new File([blob], file.name, {
        type: file.type,
        lastModified: Date.now(),
    });

    return resizedFile;
};

export function formatPriceToFrenchSystem(numStr: number) {
    return numStr
        .toFixed(2) // round to 2 decimal places
        .toString() // convert to string
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') // add spaces after every 3 digits
        .replace(".", ",") // replace the dot by a comma
}

export function formatQty(qty: number) {
    let qtyStr = `${qty}`
    let parts = qtyStr.split('.')

    if (parts.length === 1) {
        // if the the value does not have a decimal part
        return qty.toString()
    }
    if (parts[1] === '0') {
        // if the value's decimal part is 0, return the whole number
        return parts[0]
    }
    else if (parts[1] !== '0') {
        // if the the value's derimal part is greater than 0, format the value
        return qty
            .toFixed(1) // round to 1 decimal places
            .toString() // convert to string
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') // add spaces after every 3 digits
            .replace(".", ",") // replace the dot by a comma
    } else {
        return qty.toString()
    }
}

export function formatPercentageToFrenchSystem(percentage: number) {
    let newPercentage = percentage
        .toFixed(2) // round to 2 decimal places
        .toString() // convert to string
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') // add spaces after every 3 digits
        .replace(".", ",") // replace the dot by a comma

    const last_part = newPercentage.split(",").pop()

    // if decimal is 0 return a whole number
    if (last_part === "00") {
        return newPercentage.replace(",00", "")
    }

    // if decimal part has a value greater than 0 followed by a zero, remove the zero
    if (last_part && last_part[0] !== '0' && last_part[1] === '0') {
        const current_decimal_part = ',' + last_part
        const new_decimal_part = ',' + last_part[0]
        return newPercentage.replace(current_decimal_part, new_decimal_part)
    }

    return newPercentage
}

export function pad(n: number) {
    return (n < 10) ? ("0" + n) : `${n}`;
}

export function formatDate(date: string) {
    return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
}

export function getTomorrow(date: string) {
    const given_date = moment(date, 'DD/MM/YYYY')
    const tomorrow = given_date.add(1, "days")
    return tomorrow.format('DD/MM/YYYY')
}

export function timePassed(date: string) {
    const today = moment()
    const given_date = moment(date, 'DD/MM/YYYY h:mm')

    let date_string
    if (given_date.isSame(today, 'day')) {
        date_string = `Aujourd'hui à ${given_date.format("HH:mm")}`
    } else if (given_date.isSame(today, 'year') && !given_date.isSame(today, 'day')) {
        date_string = `Le ${given_date.format("DD/MM")} à ${given_date.format("HH:mm")}`
    } else if (!given_date.isSame(today, 'year')) {
        date_string = `Le ${given_date.format("DD/MM/YYYY")}`
    }
    return date_string
}

export function convoTimePassed(date: string) {
    const today = moment()
    const given_date = moment(date, 'DD/MM/YYYY h:mm')
    const formatedDate = moment(date, 'DD/MM/YYYY')

    let date_string
    if (formatedDate.isSame(today, "day")) {
        date_string = `${given_date.format("HH:mm")}`
    } else if (formatedDate.isSame(today, "week") && !formatedDate.isSame(today, 'day')) {
        date_string = given_date.format('dddd')
    } else {
        date_string = `${given_date.format("DD/MM/YYYY")}`
    }
    return date_string
}

export function dateSeperator(date: string) {
    const today = moment()
    const given_date = moment(date, 'DD/MM/YYYY')

    let date_string
    if (today.diff(given_date, "days") === 0) {
        date_string = `Aujourd'hui`
    } else if (today.diff(given_date, "weeks") === 0 && today.diff(given_date, 'days') !== 0) {
        date_string = given_date.format('dddd')
    } else {
        date_string = `${given_date.format("DD/MM/YYYY")}`
    }
    return date_string
}

export function getTimeFromDate(date: string) {
    const given_date = moment(date, 'DD/MM/YYYY h:mm')

    return `${given_date.format("HH:mm")}`
}

export function getDateFromDateTime(date: string) {
    const given_date = moment(date, 'DD/MM/YYYY h:mm')

    return `${given_date.format("DD/MM/YYYY")}`
}

export function comparedDates(a: string, b: string) {
    const date1 = moment(a, 'DD/MM/YYYY h:mm')
    const date2 = moment(b, 'DD/MM/YYYY h:mm')

    return date2.diff(date1)
}

export function extractLevelOneDataFromProposal(proposal: any): Proposal {
    return {
        id: proposal.id,
        client_budget: parseFloat(proposal.client_budget),
        expiration_date: proposal.expiration_date,
        refused_date: proposal.refused_date,
        revised_date: proposal.revised_date,
        sending_date: proposal.sending_date,
        signed_date: proposal.signed_date,
        indicative: proposal.indicative,
        nature: proposal.nature,
        proposal_ref: proposal.proposal_ref,
        status: proposal.status,
        title: proposal.title,
        total_taxincl: parseFloat(proposal.total_taxincl),
        total_taxincl_discounted: parseFloat(proposal.total_taxincl_discounted),
        updated: proposal.updated,
        version: proposal.version,
        worktype: proposal.worktype
    } as Proposal
}

export function transformTaxRepartitions(tax_repartitions: { tax: string, total: string, total_taxexcl: string }[]) {
    return tax_repartitions.map((item) => {
        return {
            tax: parseFloat(item.tax),
            total: parseFloat(item.total),
            total_taxexcl: parseFloat(item.total_taxexcl),
        }
    })
}

export function extractLevelTwoDataFromProposal(proposal: any): IProposal {
    return {
        ...extractLevelOneDataFromProposal(proposal),
        accepted_payment_modes: proposal.accepted_payment_modes,
        additional_conditions: proposal.additional_conditions,
        discount_type_is_percentage: proposal.discount_type_is_percentage,
        discount_value: parseFloat(proposal.discount_value),
        favorite_color: proposal.favorite_color,
        gallery: proposal.gallery,
        previous_version: parseInt(proposal.previous_version),
        provisional_end_date: proposal.provisional_end_date,
        provisional_start_date: proposal.provisional_start_date,
        sales_conditions: proposal.sales_conditions,
        sign_block: proposal.sign_block,
        total_tax: parseFloat(proposal.total_tax),
        total_taxexcl: parseFloat(proposal.total_taxexcl),
        payment_max_delay: proposal.payment_max_delay,
        tax_repartitions: transformTaxRepartitions(proposal.tax_repartitions),
        total_taxexcl_discounted: parseFloat(proposal.total_taxexcl_discounted),
        discount_amount: parseFloat(proposal.discount_amount),
        is_tax_exempt: proposal.is_tax_exempt
    }
}

export function extractProData(work: IWork): IWork {
    return {
        work_id: work.work_id,
        title: work.title,
        status: work.status,
        work_start_date: work.work_start_date,
        work_end_date: work.work_end_date,
        cancellation_date: work.cancellation_date,
        abortion_request_date: work.abortion_request_date,
        cancellation_first_name: work.cancellation_first_name,
        cancellation_last_name: work.cancellation_last_name
    } as IWork
}

/**
 * This is used to protect signin and signup pages from authenticated user
 * @param to 
 * @param from 
 * @param next 
 */
// export function canGoToAuthPage(to: any, from: any, next: any) {
//     const router = useRouter();
//
//     if (!isAuth()) {
//         next();
//     } else {
//         router.push("/cs/home")
//     }
// }

/**
 * This function is used to create the user session based on if he want to be keep connected or not
 * @param data 
 * @param rememberMe 
 */
export async function createUserAuthSession(data: any, rememberMe: any): Promise<void> {
    // After user is logged in, create session

    let token = data.token
    token.account_id = data.account_id

    if (typeof window !== 'undefined') {
        if (rememberMe.value === true) {
            const expirationDate = moment().add(13, 'months').toDate(); // Get the expiration date 13 months from now
            document.cookie = `topela_token=${JSON.stringify(token)};expires=${expirationDate.toUTCString()};path=/`;
            data.token = token as AuthTokenData
            sessionStorage.setItem("user", JSON.stringify(data as IUser))
        } else {
            // if user has not selected remember me, set the auth token in cookie
            // since cookie is data is short lived
            document.cookie = "topela_token=" + JSON.stringify(token)
            data.token = token as AuthTokenData
            sessionStorage.setItem("user", JSON.stringify(data as IUser))
        }
    }
}

/**
 * This function check if there is a stored user and return it for the session
 * @returns 
 */
export function getUserFromLocalStorage(): User | null {
    if (typeof window !== 'undefined') {
        const userStore = useUserStore()
        if (userStore.getUser.account_id) {
            return userStore.getUser
        }

        let userString = sessionStorage.getItem('user')
        if (!userString) return null

        userStore.addUser(JSON.parse(userString) as IUser)

        return userStore.getUser.account_id ? userStore.getUser : JSON.parse(userString) as IUser
    }

    return null
}

/**
 * This function is used to verify either the user is authenticated or not
 * @returns 
 */
export function isAuth(): boolean {
    let isAuthenticatedState = false

    const cookieToken = getCookie("topela_token")
    if (cookieToken !== null) {
        isAuthenticatedState = true
    }

    return isAuthenticatedState
}

/**
 * This is used to get the topela_token stored in the the cookies
 * @param name 
 * @returns 
 */
export function getCookie(name: string) {
    if (typeof window !== 'undefined') {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            let cookieToken = parts?.pop()?.split(';').shift();
            if (cookieToken !== null && cookieToken !== undefined) {
                return JSON.parse(cookieToken)
            }
            return null
        }
    }
    return null
}

export async function logout() {
    if (typeof window !== 'undefined') {
        const auth = useAuthStore();

        sessionStorage.removeItem("user")
        const cookieToken = getCookie("topela_token")
        if (cookieToken !== null) {
            document.cookie = 'topela_token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        }
        auth.setIsLoggedIn(false)
        // router.push("/")
        await navigate('/')
    }
}

/**
 * This used to protect the client space from inactive users
 * @param to 
 * @param from 
 * @param next 
 */
// export function canActivate(to: any, from: any, next: any) {
//     if (from.path === "/auth/login") {
//         next();
//         return
//     }
//
//     const cookieToken = getCookie("topela_token")
//     if (cookieToken === null) {
//         logout()
//     } else {
//
//         const user = getUserFromLocalStorage()
//         if (user !== null && user.is_active && user.status === GeneralStatus.g_lst_gen_status_active) {
//             next();
//         } else {
//             const userStore = useUserStore()
//             const auth = useAuthStore()
//             getProfileInfo()
//                 .then((response) => {
//                     let user_info: IUser = response.data
//                     sessionStorage.setItem('user', JSON.stringify(user_info))
//                     userStore.addUser(user_info)
//                     auth.setIsLoggedIn(true)
//                     next()
//                 })
//                 .catch((err: any) => {
//                     if (err.response?.data.code == ErrorResponse.USER_NOT_FOUND) {
//                         logout()
//                     }
//                 })
//         }
//     }
// }

/**
 * This used to fetch user data if the cookie is set
 * @param to 
 * @param from 
 * @param next 
 */
// export function freeActivate(to: any, from: any, next: any) {
//     const cookieToken = getCookie("topela_token")
//     if (cookieToken === null) {
//         next()
//     } else {
//         const user = getUserFromLocalStorage()
//         if (user !== null && user.is_active && user.status === GeneralStatus.g_lst_gen_status_active) {
//             next();
//         } else {
//             const userStore = useUserStore()
//             const auth = useAuthStore()
//             getProfileInfo()
//                 .then((response) => {
//                     let user_info: IUser = response.data
//
//                     sessionStorage.setItem('user', JSON.stringify(user_info))
//                     userStore.addUser(user_info)
//                     auth.setIsLoggedIn(true)
//                     next()
//                 })
//                 .catch((err: any) => {
//                     if (err.response?.data.code == ErrorResponse.USER_NOT_FOUND) {
//                         logout()
//                     }
//                 })
//         }
//     }
// }

export function replaceEmptyWithNull(value: any) {
    return value === '' ? null : value;
};

export function formateCompanyReference(value: any): string {
    if (!value) {
        return ''
    }

    try {
        //replace all spaces if needed
        value = value.replace(/\s/g, '')

        // Define the lengths of each block
        const blockLengths = [3, 3, 3, 5];

        let formattedValue = '';
        let index = 0;

        // Construct the formatted value with optional blocks
        for (let length of blockLengths) {
            if (index < value.length) {
                formattedValue += value.substr(index, length);
                if (index + length < value.length) {
                    formattedValue += ' ';
                }
                index += length;
            } else {
                break;
            }
        }

        return formattedValue;
    } catch (e) {
        return ''
    }
}