import { defineStore, storeToRefs } from "pinia";
import { Ref, computed, ref } from "vue";
import { deleteAvatarService, updateAvatar } from '@/services'
import { useAlertsStore } from '@/stores/alerts'
import { useUserStore } from "@/stores/user";
import { getUserFromLocalStorage } from "@/utils/functions";

export const useAvatarFormStore = defineStore('avatar-form', () => {
    const avatarBlob: Ref<Blob | null> = ref(null)

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const getAvatarUrl = computed(() => {
        if (!avatarBlob.value) return null
        return URL.createObjectURL(avatarBlob.value)
    })

    const hasChanges = computed(() => {
        return avatarBlob.value !== null
    })

    const uploadAvatar = (blob: Blob) => {
        resizeImage(blob)
    }

    const resizeImage = (blob: Blob) => {
        const reader = new FileReader()
        reader.onload = (event: any) => {
            const image = new Image()
            image.src = event.target.result
            image.onload = () => {
                const canvas = document.createElement('canvas')
                const context = canvas.getContext('2d')
                canvas.width = 100
                canvas.height = 100

                if (context) {
                    // Draw white background
                    context.fillStyle = 'white'
                    context?.fillRect(0, 0, canvas.width, canvas.height)

                    context?.drawImage(image, 0, 0, 100, 100)
                    canvas.toBlob((blob) => {
                        if (blob) {
                            avatarBlob.value = new File([blob], 'avatar.webp', { type: 'image/jpeg' })
                        }
                    }, 'image/jpeg', 1)
                }
            }
        }

        reader.readAsDataURL(blob)
    }

    const deleteAvatar = async () => {
        try {
            const response = await deleteAvatarService()
            clearAvatarUpload()
            
            const userStore = useUserStore()
            userStore.user.avatar = null

            // TODO: SHOULD CHANGE THIS
            const alerts = useAlertsStore()
            alerts.putAlert({ 'code': 'avatarChangeSuccess', 'scope': 'general' })

            // let userInLocalStorage = JSON.parse(sessionStorage.getItem("user") ?? '')
            // userInLocalStorage.avatar = null
            const userInLocalStorage = getUserFromLocalStorage()
            userInLocalStorage!.avatar = null
            // userStore.addUser(userInLocalStorage)
            sessionStorage.setItem('user', JSON.stringify(userInLocalStorage))
        } catch (error) {}
    }

    const saveChanges = async () => {  
        if (avatarBlob.value !== null) {
            let payload = {
                // "avatar": avatarFile.value
                "avatar": avatarBlob.value
            }
        
            let response = await updateAvatar(payload)
            clearAvatarUpload()
            user.value.avatar = response.data.avatar

            try {
                const alerts = useAlertsStore()
                alerts.putAlert({ 'code': 'avatarChangeSuccess', 'scope': 'general' })

                // let userInLocalStorage = JSON.parse(sessionStorage.getItem("user") ?? '')
                const userInLocalStorage = getUserFromLocalStorage()
                userInLocalStorage!.avatar = user.value.avatar
                // userStore.addUser(userInLocalStorage)
                sessionStorage.setItem('user', JSON.stringify(userInLocalStorage))
            } catch(error) {
                const alerts = useAlertsStore()
                alerts.putAlert({ 'code': 'avatarChangeFail', 'scope': 'general' })
                // console.error(error)
            }
        }
    }

    const clearAvatarUpload = () => {
        avatarBlob.value = null
    }

    const clearData = () => {
        const alerts = useAlertsStore()
        alerts.clearAlerts()
        clearAvatarUpload()
    }

    return { 
        avatarBlob, 
        getAvatarUrl,
        hasChanges,
        
        uploadAvatar, 
        deleteAvatar,  
        clearAvatarUpload, 
        saveChanges, 
        clearData
    }
})