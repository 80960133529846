import { defineStore } from "pinia";
import moment from "moment-timezone";
import { User } from "@/models/User";
import { IUser } from "@/models/interfaces/interfaces";
import { useProfileStore } from '@/pages/cs/profil/profileStore.ts'
import { getCookie } from "@/utils/functions";
import {Address} from "@/models/Address.ts";

/**
 * Represents the state management for a connected user
 */
export const useUserStore = defineStore('user', {
    state: () => ({
        user: new User({} as IUser)
    }),
    getters: {
        getUser: (state) => state.user,
        getIsImageExpired: (state) => {
            let isExpired = false

            if (state.user.avatar) {
                let params = new URL(state.user.avatar).searchParams;
                // @ts-ignore
                const expiresParam: number = params.get('Expires')

                // Verify if the avatar url has query param called 'Expires'
                if (expiresParam) {
                    const today = moment()
                    // Multiply by 1000 because UNIX timestamp which is used by
                    // minio expires param in this case, is expressed in seconds
                    // While dates in javascript are typically expressed in miliseconds
                    const expiresDate = moment(expiresParam * 1000)

                    const diff = expiresDate.diff(today, 'milliseconds')

                    if (diff < 0) {
                        isExpired = true
                    }
                } else {
                }
            } else {
            }

            return isExpired
        }
    },
    actions: {
        addUser(userData: IUser) {
            const cookieToken = getCookie('topela_token')
            
            let newUser:IUser = userData

            // Attach the auth token to the user
            let token: { refresh: string, access: string, account_id: string } = cookieToken ?? JSON.parse('{}')
            newUser['token'] = token
            this.user = new User(newUser)
            if (newUser.address) {
                this.user.address = new Address(newUser.address)
            }

            this.user.token = token

            const profileStore = useProfileStore()
            profileStore.clearData()
        }
    }
})