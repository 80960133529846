const LOGIN_FAILED_MESSAGE = "login failed"
const LOGIN_ATTEMPT_LIMIT_MESSAGE = "Login attempt reached"
const STATUS_LOGIN_FAILLED = 404
const STATUS_EXCEEDED_LIMIT = 429
const STATUS_LOGIN_500 = 500


const INVALID_PHONE_ERROR_MESSAGE = "Numéro de téléphone invalide"
const USERNAME_TAKEN_ERROR_MESSAGE = "Pseudo déjà utilisé"
const INVALID_PHONE_ERROR_CODE = 406

export default {
    LOGIN_FAILED_MESSAGE,
    LOGIN_ATTEMPT_LIMIT_MESSAGE,
    STATUS_LOGIN_FAILLED,
    STATUS_EXCEEDED_LIMIT,
    STATUS_LOGIN_500,
    INVALID_PHONE_ERROR_MESSAGE,
    INVALID_PHONE_ERROR_CODE,
    USERNAME_TAKEN_ERROR_MESSAGE
}