import { defineStore, getActivePinia } from "pinia";
import { useAvatarFormStore } from "./avatarForm/avatarForm";
import { useAuthInfoFormStore } from "./authInfoform/authInfoForm";
import { useUserFavoriteContactStore } from "./favoriteContactForm/userFavoriteContactForm";
import { useUserInfoFormStore } from "./userInfoForm/userInfoForm";
import { computed, ref } from "vue";
import { useCompanyDetailStore } from "./companyDetailsForm/useCompanyDetailsForm";

export const useProfileStore = defineStore('user-profile', () => {
    const avatarFormStore = useAvatarFormStore()
    const authInfoFormStore = useAuthInfoFormStore()
    const userFavoriteContactStore = useUserFavoriteContactStore()
    const userInfoFormStore = useUserInfoFormStore()
    const companyDetailStore = useCompanyDetailStore()
    const leaveWithoutSaving = ref(false)
    const blockingPageLeave = ref(false) // flag to avoid infinite loop on profile rerender when there are changes
    const onProfilePage = ref(false)

    const pinia = getActivePinia()

    const hasChanges = computed(() => {
        return (
            avatarFormStore.hasChanges ||
            authInfoFormStore.hasChanges ||
            userFavoriteContactStore.hasChanges ||
            userInfoFormStore.hasChanges ||
            companyDetailStore.hasChanges
        )
    })

    const clearData = () => {
        avatarFormStore.clearData()
        authInfoFormStore.clearData()
        userFavoriteContactStore.clearData()
        userInfoFormStore.clearData()
        companyDetailStore.clearData()
        leaveWithoutSaving.value = false
        blockingPageLeave.value = false
        onProfilePage.value = false
    }

    const resetFormStores = () => {
        avatarFormStore.$dispose()
        delete pinia?.state.value[avatarFormStore.$id]

        authInfoFormStore.$dispose()
        delete pinia?.state.value[authInfoFormStore.$id]

        userFavoriteContactStore.$dispose()
        delete pinia?.state.value[userFavoriteContactStore.$id]

        userInfoFormStore.$dispose()
        delete pinia?.state.value[userInfoFormStore.$id]

        companyDetailStore.$dispose()
        delete pinia?.state.value[companyDetailStore.$id]
    }

    return {
        hasChanges,
        resetFormStores,
        clearData,
        leaveWithoutSaving,
        blockingPageLeave,
        onProfilePage
    }
})