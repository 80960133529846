import { getAppInstance } from './appInstance'

export { onPageTransitionStart }

import type { OnPageTransitionStartAsync } from 'vike/types'

const onPageTransitionStart: OnPageTransitionStartAsync = async (
    pageContext
): ReturnType<OnPageTransitionStartAsync> => {
    const app = getAppInstance()
    app.config.globalProperties.$Progress.start()
}