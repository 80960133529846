import Axios from "axios";

const instance = Axios.create({
    // @ts-ignore
    baseURL: import.meta.env.VITE_BACKEND_BASE_URL,
    responseType: 'json',
    timeout: 40000
})

const axios = instance

export { axios }