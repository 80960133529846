import * as yup from 'yup'
import i18n from '@/localization/i18n'

const t = i18n.global.t

const phoneRegex = /^(06|07)[0-9]{8}$|^$/

const schema = yup.object().shape({
  first_name: yup
            .string()
            .max(150, t('w_msg_frmVal_maxCharacters', { num: 150 }))
            .required(t('w_msg_frmVal_required')),
  last_name: yup
            .string()
            .required(t('w_msg_frmVal_required'))
            .max(150, t('w_msg_frmVal_maxCharacters', { num: 150 })),
  username: yup
            .string()
            .required(t('w_msg_frmVal_required'))
            .min(3, t('w_msg_frmVal_minCharacters', { num: 3 }))
            .max(30, t('w_msg_frmVal_maxCharacters', { num: 30 })),
  gender: yup.string()
            .required(t('w_msg_frmVal_required')),
  phone: yup.string()
            .nullable()
            .matches(phoneRegex, t('w_msg_frmVal_phoneFormat'))
            .max(10, t('w_msg_frmVal_maxDigits', { num: 10 }))
})

export default schema