// @ts-ignore
import { createI18n } from 'vue-i18n'

import locales from '@/localization/locales/locales.json'

// Returns client set locale otherwise defaults to 'fr'
function getLocale() {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        return localStorage.getItem("locale") ?? 'fr'
    }
    return 'fr'
}

// Create VueI18n instance with options
const i18n = createI18n({
    locale: getLocale(),
    messages: locales,
    fallbackLocale: 'fr',
    legacy: false, // you must set `false`, to use Composition API
})

export default i18n